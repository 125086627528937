<template>
  <div class="elem-job company radius box-shadow" @click="applicantStatus">
    <div class="content-job">
      <div class="title-job">
        {{elem.name}}
      </div>
      <div class="desc-job" v-if="elem.description !== ''">
        {{elem.description.slice(0,60)}}...
      </div>
      <div class="desc-job" v-else><br></div>
      <div class="sub-job">
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')">
          </div>
          <div class="info-sub">
            {{elem.contract}}
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
          </div>
          <div class="info-sub">
            {{elem.location}}
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img class="small" :src="require('@/assets/pictos/picto_sector.svg')">
          </div>
          <div class="info-sub">
            {{elem.sector}}
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img :src="require('@/assets/pictos/picto_contract_darkpurple.svg')">
          </div>
          <div class="info-sub">
            {{elem.diffusion}}
          </div>
        </div>
        <div class="elem-sub">
          <div class="picto-sub">
            <img class="small" :src="require('@/assets/pictos/picto_remote.svg')">
          </div>
          <div class="info-sub" v-if="elem.remote != 'Partial'">
            {{elem.remote}}
          </div>
          <div class="info-sub" v-else>
            Partial remote
          </div>
        </div>
      </div>
    </div>
    <div class="infop-job radius">
      <div class="line-infop">
        <span>{{getNewApplicantNumber}}</span>
        New Applicants
      </div>
      <div class="separ"></div>
      <div class="line-infop">
        <span>{{getApplicantNumber}}</span>
        Applicants
      </div>
      <div class="separ"></div>
      <div class="line-infop">
        <span>{{getRunningProcessesNumber}}</span>
        Ongoing Processes
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'JobElemCompDash',
  props: {
    elem: Object,
  },

  data() {
    return {
    };
  },
  computed: {
    getNewApplicantNumber() {
      return 0;
    },
    getApplicantNumber() {
      return this.elem.applicants.length;
    },
    getRunningProcessesNumber() {
      return this.elem.applicants.filter((el) => el.status === 'approved').length;
    },
  },
  mounted() {
  },
  methods: {
    redirect() {
      this.$router.push(`/offer/${this.elem._id}`);
    },
    applicantStatus() {
      this.$store.dispatch('company/setCurrentOfferApplicationId', this.elem._id);
      this.$router.push('/application-management');
    },
  },
};
</script>

<style lang="scss" scoped>
  .elem-job{
    cursor: pointer;

      .small{
        width: 70% !important ;
      }

  }
</style>
