<template>
  <div class="component-fullwidth">
    <div id="dashboard" v-if="!loading">
      <div class="flex-container flex-full no-padding no-margin">
        <div class="elem-flex">
          <div class="title-page">
            {{ $t("page_title_dashboard") }}
          </div>
        </div>
        <div class="elem-flex">
          <div class="subtitle-page no-margin">
            <span v-html='$t("dash_upcoming_subtitle")'></span>
          </div>
          <div id="dash-upcoming-splide"
            v-if="events.length > 0"
            class=""
          >
            <splide class="list-splide-upcoming splidepadding"
              v-if="events.length <= 3"
              :options="optionsUpcomingSolo"
            >
              <splide-slide
                class="elem-splide-upcoming box-shadow radius"
                v-for="event in events"
                :key="event._id"
              >
                <div
                  class="link"
                  v-if="event.processRef"
                  @click="viewProcess(event)"
                ></div>
                <div class="text">
                  <div class="title-upcoming">
                    {{event.title}}
                  </div>
                </div>
                <div class="hours">
                  <span>{{event.date.start | moment('HH:mm a')}}</span>
                  <span class="separ"></span>
                  <span>{{event.date.end | moment('HH:mm a')}}</span>
                </div>
              </splide-slide>
            </splide>
            <splide class="list-splide-upcoming splidepadding"
              v-else
              :options="optionsUpcomingMultiple"
            >
              <splide-slide
                class="elem-splide-upcoming box-shadow radius"
                v-for="event in events"
                :key="event._id"
              >
                <div
                  class="link"
                  v-if="event.processRef"
                  @click="viewProcess(event)"
                ></div>
                <div class="text">
                  <div class="title-upcoming">
                    {{event.title}}
                  </div>
                </div>
                <div class="hours">
                  <span>{{event.date.start | moment('HH:mm a')}}</span>
                  <span class="separ"></span>
                  <span>{{event.date.end | moment('HH:mm a')}}</span>
                </div>
              </splide-slide>
            </splide>
          </div>
          <div id="dash-upcoming-splide"
            v-else
            class=""
          >
            <splide class="list-splide-upcoming splidepadding" :options="optionsUpcomingSolo">
              <splide-slide class="elem-splide-upcoming box-shadow radius-small no-coming">
                {{ $t("upcoming_no_event") }}
              </splide-slide>
            </splide>
          </div>
        </div>
        <div class="elem-flex">
          <div class="subtitle-page no-margin">
            {{ $t("dash_currentjoboffer_subtitle") }} ({{companyInfo.offers.length}})
          </div>
          <div id="dash-offer-splide"
            v-if="companyInfo.offers.length > 0"
            class=""
          >
            <splide class="list-splide-offer dash-splide splidepadding"
              v-if="companyInfo.offers.length <= 1"
              :options="optionsOfferSolo"
            >
              <splide-slide
                class="elem-splide-offer"
                v-for="offer in companyInfo.offers"
                :key="offer._id"
              >
                <JobElemCompDash
                  :elem="offer"
                />
              </splide-slide>
            </splide>
            <splide class="list-splide-offer dash-splide-spe splidepadding"
              v-else
              :options="optionsOfferMultiple"
            >
              <splide-slide
                class="elem-splide-offer"
                v-for="offer in companyInfo.offers"
                :key="offer._id"
              >
                <JobElemCompDash
                  :elem="offer"
                />
              </splide-slide>
            </splide>
          </div>
          <div
            class="dash-upcoming-splide"
            v-else
          >
            <splide class="list-splide-upcoming" :options="optionsUpcomingSolo">
              <splide-slide class="elem-splide-upcoming box-shadow radius-small no-app">
                No Current Application<br><br>
                <span>
                  You can find job offer through search field
                </span>
              </splide-slide>
            </splide>
          </div>
        </div>
        <div class="elem-flex">
          <div class="subtitle-page no-margin wother">
            {{ $t("dash_lastnewsfeed_subtitle") }}
            <div class="other">
              <router-link to="/feed">
                All Feed
              </router-link>
            </div>
          </div>
          <div class="dash-feed-splide"
            v-if="feedPosts.length > 0"
          >
            <splide class="list-splide-feed dash-splide splidepadding"
              v-if="feedPosts.length <= 3"
              :options="optionsFeedSolo"
            >
              <splide-slide
                class="elem-splide-feed box-shadow radius-small"
                v-for="postFeed in feedPosts"
                :key="postFeed._id"
              >
                <post-view :post="postFeed" :viewonly="false"/>
              </splide-slide>
            </splide>
            <splide class="list-splide-feed dash-splide splidepadding"
              v-else
              :options="optionsFeedMultiple"
            >
              <splide-slide
                class="elem-splide-feed box-shadow radius-small"
                v-for="postFeed in feedPosts"
                :key="postFeed._id"
              >
                <post-view :post="postFeed" :viewonly="false"/>
              </splide-slide>
            </splide>
          </div>
        </div>
      </div>
    </div>
    <loading v-else />
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import Loading from '@/components/utilityPartials/_loading.vue';
import PostView from '@/components/UniversalComponents/Feed/_post.vue';
import JobElemCompDash from '@/components/UniversalComponents/Offer/JobElemCompDash.vue';
import ApplicantProcess from '@/components/EmployeeComponents/JobManagement/processPartials/applicantProcess.vue';

export default {
  name: 'EmployeeDashboard',
  components: {
    Loading,
    PostView,
    JobElemCompDash,
    Splide,
    SplideSlide,
  },
  computed: {
    ...mapState('company', {
      companyInfo: (state) => state.companyInfo,
      loading: (state) => state.loadingInfo,
    }),
    feedPosts() {
      const tempFeed = this.$store.state.feed.myFeed.feedPosts;
      return tempFeed.slice(0, 9);
    },
    events() {
      const upcomingevents = this.$store.getters['calendar/upcoming']();
      return upcomingevents;
    },
  },
  methods: {
    viewProcess(event) {
      const applicant = this.$store.getters['company/applicant'](event.processRef.offer, event.processRef.applicant);
      this.$modal.show(ApplicantProcess,
        {
          applicant,
          processComp: ApplicantProcess,
          offerIdProp: event.processRef.offer,
        },
        {
          name: 'applicant-process',
          width: '80%',
          height: '70vh',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        });
    },
  },
  data() {
    return {
      optionsUpcomingSolo: {
        perPage: 3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        pagination: false,
        arrows: false,
        drag: false,
      },
      optionsUpcomingMultiple: {
        perPage: 3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        pagination: false,
        drag: false,
      },
      optionsFeedSolo: {
        perPage: 3,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        arrows: false,
        pagination: false,
        drag: false,
      },
      optionsFeedMultiple: {
        perPage: 3,
        perMove: 1,
        gap: '2vh',
        padding: '1vh',
        rewind: true,
        drag: false,
      },
      optionsOfferSolo: {
        gap: '1vh',
        padding: '1vh',
        rewind: true,
        arrows: false,
        pagination: false,
        drag: false,
      },
      optionsOfferMultiple: {
        gap: '1vh',
        perPage: 1,
        padding: {
          right: '40vh',
          left: '0',
        },
        rewind: true,
        pagination: false,
        drag: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch('company/getInfo');
    this.$store.dispatch('calendar/getCalendar');
    this.$store.dispatch('company/getOffers');
    this.$store.dispatch('feed/myFeed/getInitialPosts');
  },

};
</script>

<style lang="scss" >
  @import "@/assets/scss/_splide.scss";
  .dash-upcoming-splide{
    &:nth-child(1){
      .splide__list{
        padding: 0 0 !important;
      }
    }
    &:nth-child(2){
      .splide__list{
        padding: 0 0 !important;
      }
      .splide__track{
        padding-top: 1vh;
        padding-bottom: 1vh;
      }
    }
  }
</style>
